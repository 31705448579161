import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useDrag } from "react-dnd";
import Types from "./ItemTypes";

const DraggableBox = ({ children, value, dropped }) => {
	const [{ isDragging }, drag] = useDrag({
		item: { value: value, type: Types.WORD },
		canDrag: !dropped,
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging()
		})
	});

	return (
		<Box
			style={{
				cursor: dropped ? "not-allowed" : "move",
				opacity: dropped ? 0 : 1,
				// paddingTop: "px",
				// paddingBottom: "5px",
				paddingLeft: "25px",
				paddingRight: "25px"
			}}
			ref={drag}
		>
			<Typography component="div" variant="h5">
				<Box
					fontWeight={isDragging ? "bold" : "normal"}
					color="#919eba"
				>
					{children}
				</Box>
			</Typography>
		</Box>
	);
};

export default DraggableBox;
