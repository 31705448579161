import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import ColoredButton from "../Utils/ColoredButton";
import axios from "axios";
import { CurrentHost } from "../Hosts";
import { StepContext } from "../Step";

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: "100%"
	},
	titleText: {
		fontWeight: "bold",
		fontSize: "4vh",
		width: "60%"
	}
});

const renderQuestions = (questionNumber) => {
	let questions = [];
	for (let i = 1; i <= questionNumber; i++) {
		questions.push(
			<Grid item key={i}>
				<TextField
					// classes={{ root: { fontSize: "3vh" } }}
					name={`input-${i}`}
					required
					InputProps={{
						style: { fontSize: "3vh" }
					}}
					InputLabelProps={{
						style: { fontSize: "3vh" }
					}}
					label={`${i}.`}
				/>
			</Grid>
		);
	}
	return questions;
};

export const FillInAnswerTask = () => {
	const classes = useStyles();
	const { data, solved, sessionKey } = useContext(StepContext);

	const onSubmit = () => {
		axios
			.post(`${CurrentHost}:5001/steps/ForceComplete`, {
				key: sessionKey
			})
			.then((response) => {
				if (response.data.success) {
					solved(true);
				}
			});
	};

	return (
		<Grid
			container
			className={classes.root}
			direction="column"
			alignItems="center"
			justify="space-evenly"
		>
			<Grid
				item
				container
				style={{ width: "100%" }}
				justify="center"
				alignItems="flex-end"
			>
				<Typography align="center" className={classes.titleText}>
					{data.Statement}
				</Typography>
			</Grid>
			<Grid
				item
				container
				direction="column"
				alignItems="center"
				justify="center"
			>
				<Form
					onSubmit={onSubmit}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								alignItems="center"
								justify="center"
								direction="column"
							>
								{renderQuestions(data.FillInNumber)}
								<Grid item>
									<ColoredButton
										color="rgb(72, 200, 54)"
										variant="contained"
										type="submit"
									>
										<Typography
											style={{
												fontWeight: "bold",
												fontSize: "3vh"
											}}
										>
											Neste
										</Typography>
									</ColoredButton>
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</Grid>
		</Grid>
	);
};
