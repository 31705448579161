import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const useStyles = makeStyles({
	root: {
		color: "#c8cedc",
		"&:hover": {
			color: "#1aa6db",
			backgroundColor: "transparent"
		}
	},
	rootChecked: {
		color: "#c8cedc"
	},
	icon: {
		fontSize: ({ size }) => (size != null ? size : "2em")
	}
});

const CustomCheckbox = ({ size, ...otherProps }) => {
	const classes = useStyles({ size });
	return (
		<Checkbox
			disableRipple
			color="default"
			classes={{
				root: classes.root,
				checked: classes.rootChecked
			}}
			icon={<RadioButtonUncheckedIcon className={classes.icon} />}
			checkedIcon={<RadioButtonCheckedIcon className={classes.icon} />}
			{...otherProps}
		/>
	);
};

export default CustomCheckbox;
