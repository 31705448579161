import React, { useContext } from "react";
import { CurrentHost } from "../Hosts";
import ReactPlayer from "react-player";
import { Box } from "@material-ui/core";
import axios from "axios";
import "./VideoTask.css";
import { StepContext } from "../Step";

export const VideoTask = () => {
	const { sessionKey, nextStep, data } = useContext(StepContext);
	const handleEnd = () => {
		axios
			.post(`${CurrentHost}:5001/steps/ForceComplete`, {
				key: sessionKey
			})
			.then((response) => {
				if (response.data.success) {
					nextStep();
				}
			});
	};

	return (
		<Box className="videoBackdrop">
			<Box className="videoContainer">
				<ReactPlayer
					url={data.Url}
					config={{
						vimeo: {
							playerOptions: {
								texttrack: "no"
							}
						}
					}}
					width="100%"
					height="100%"
					playing
					onEnded={handleEnd}
					controls={true}
				/>
			</Box>
		</Box>
	);
};
