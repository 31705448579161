import React, { useContext } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Next from "../../img/next.svg";
import Lottie from "react-lottie";
import BadAnswerAnimation from "../../animations/lf30_editor_rixaGJ.json";
import { StepContext } from "../Step";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%",
		width: "100%",
		backgroundColor: "#f5f8f9",
		borderRadius: "20px"
	},
	cancelIcon: {
		width: "25vh",
		height: "25vh",
		color: "#c8cedc"
	},

	titleText: {
		fontWeight: "bold",
		//fontSize: "3rem",
		color: "#4d5770"
	},
	nextIcon: {
		width: "7vmax",
		height: "7vmax",
		//minWidth: "50%",
		objectFit: "contain",
		cursor: "pointer"
	}
});

export const WrongAnswer = () => {
	const classes = useStyles();
	const { nextStep, currentMessage } = useContext(StepContext);

	const lottieOptions = {
		loop: false,
		autoplay: true,
		display: true,
		animationData: BadAnswerAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	return (
		<Grid
			container
			alignItems="center"
			justify="center"
			alignContent="space-between"
			className={classes.rootGrid}
		>
			<Grid
				item
				container
				alignItems="center"
				justify="center"
				spacing={3}
			>
				<Grid item xs={12}>
					<Lottie
						options={lottieOptions}
						height="10vmax"
						width="10vmax"
						margin={10}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography
						className={classes.titleText}
						variant="h3"
						align="center"
					>
						Det var ikke helt riktig. Lykke til på neste spørsmål!
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={11}>
				<Typography
					style={{ color: "#4d5770" }}
					align="center"
					variant="h4"
				>
					{currentMessage}
				</Typography>
			</Grid>
			<Grid item xs={12} container justify="flex-end" alignItems="center">
				<Grid item>
					<img
						src={Next}
						className={classes.nextIcon}
						onClick={nextStep}
						alt="Next button"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
