import React, { useContext } from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CurrentHost } from "../Hosts";
import { StepContext } from "../Step";
import axios from "axios";
import ColoredButton from "../Utils/ColoredButton";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%",
		width: "100%"
	},
	correctButton: {
		backgroundColor: "green"
	},
	normalButton: {}
});

export const QuestionSingleAnswerTask = () => {
	const classes = useStyles();
	const { sessionKey, data, solved, message } = useContext(StepContext);
	const PostAnwer = (answer) => {
		const data = {
			key: sessionKey,
			answer: answer
		};
		axios
			.post(`${CurrentHost}:5001/steps/QuestionSingleAnswer`, data)
			.then((response) => {
				solved(response.data.success);
				message(response.data.message);
			});
	};

	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.rootGrid}
		>
			<Grid
				item
				container
				justify="center"
				xs={11}
				style={{ marginTop: "6rem" }}
			>
				<Typography style={{ fontSize: "6vh" }}>
					{data.Question}
				</Typography>
			</Grid>
			<Grid
				item
				container
				justify="center"
				alignItems="center"
				spacing={3}
			>
				{data.Answers.map((answer) => {
					return (
						<Grid item key={answer}>
							<ColoredButton
								color={
									answer === "Sant"
										? "green"
										: answer === "Usant"
										? "red"
										: "blue"
								}
								onClick={() => PostAnwer(answer)}
							>
								<Typography style={{ fontSize: "5vh" }}>
									{answer}
								</Typography>
							</ColoredButton>
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
};
