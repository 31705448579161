import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { useDrop } from "react-dnd";
import Types from "./ItemTypes";

const DroppableBox = ({ answer, dropWord, dropped }) => {
	const [{ isOver }, drop] = useDrop({
		accept: Types.WORD,
		canDrop: (item) => canDropAnswer(item),
		drop: (item) => dropWord(item.value),
		collect: (mon) => ({
			isOver: !!mon.isOver()
		})
	});

	const canDropAnswer = (item) => {
		return answer === item.value;
	};

	return (
		<div ref={drop}>
			<Box
				//style={{ cursor: "normal" }}
				borderBottom={1}
				borderColor={isOver ? "lightblue" : "black"}
				color={dropped != null ? "rgb(72, 200, 54)" : "black"}
			>
				{dropped != null ? dropped : "                           "}
			</Box>
		</div>
	);
};

const DroppableQuestion = ({
	question,
	answer,
	index,
	wordDropped,
	dropped
}) => {
	const drop = (value) => wordDropped(index, value);

	const getSplitQuestions = () => {
		let returnArray = [];
		const wordList = question.split(" ");
		wordList.forEach((word) => {
			if (returnArray.length !== 0) returnArray.push(" ");
			if (word !== "_") {
				returnArray.push(word);
			} else {
				returnArray.push(
					<DroppableBox
						answer={answer}
						dropWord={drop}
						dropped={dropped}
					/>
				);
			}
		});
		return returnArray;
	};

	return (
		<Box whiteSpace="pre">
			<Grid container>
				{getSplitQuestions().map((split, index) => (
					<Grid item key={index}>
						<Typography
							style={{
								fontWeight: "bold",
								color: "#4d5770"
							}}
							component="div"
						>
							{split}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default DroppableQuestion;
