import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

//import { NavMenu } from "./NavMenu";

const useStyles = makeStyles({
	root: {
		height: "100vh",
		width: "100vw",
		//backgroundColor: "gray",
		objectFit: "contain",
		maxWidth: "none",
		padding: "0px"
	}
});

export const Layout = (props) => {
	//const classes = useStyles();
	return (
		<Container maxWidth="xl" style={{ height: "100vh" }}>
			{props.children}
		</Container>
	);
};
