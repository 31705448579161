import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DraggableBox from "./DraggableBox";
import DroppableQuestion from "./DroppableQuestion";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { CurrentHost } from "../../Hosts";
import axios from "axios";
import { StepContext } from "../../Step";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%"
		//width: "100%",
	},
	wordContainer: {
		flexGrow: 1,
		borderBottom: "solid",
		borderBottomWidth: "6px",
		borderBottomColor: "#d93e00"
	},
	sentenceContainer: {
		flexGrow: 3,
		paddingTop: "2vh",
		background: `linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px`,
		backgroundSize: "100% 30px"
	}
});

export const DragAndDropAnswersTask = () => {
	const classes = useStyles();
	const { data, sessionKey, solved } = useContext(StepContext);
	const { Title, CorrectSequence, Questions, Words } = data;
	const [droppedWords, setDroppedWords] = useState([]);

	useEffect(() => {
		if (droppedWords.length === CorrectSequence.length) {
			let noHoles = true;
			for (let i = 0; i < droppedWords.length; i++) {
				if (droppedWords[i] == null) {
					noHoles = false;
					break;
				}
			}
			if (noHoles) {
				axios
					.post(`${CurrentHost}:5001/steps/ForceComplete`, {
						key: sessionKey
					})
					.then((response) => {
						if (response.data.success) {
							solved(true);
						}
					});
			}
		}
	}, [droppedWords, CorrectSequence.length, sessionKey, solved]);

	const dropWord = (index, word) => {
		let words = [...droppedWords];
		words[index] = word;
		setDroppedWords(words);
	};

	const renderQuestions = () => {
		return (
			<Grid item container spacing={3} xs={11}>
				{Questions.map((question, index) => (
					<Grid item xs={12} key={index}>
						<DroppableQuestion
							question={question}
							wordDropped={dropWord}
							answer={CorrectSequence[index]}
							index={index}
							dropped={droppedWords[index]}
						/>
					</Grid>
				))}
			</Grid>
		);
	};

	const renderWords = () => {
		return (
			<Grid
				item
				xs={7}
				container
				justify="space-around"
				alignItems="center"
			>
				{Words.map((word, index) => (
					<Grid item key={index}>
						<DraggableBox
							value={word}
							dropped={droppedWords.includes(word)}
						>
							{word}
						</DraggableBox>
					</Grid>
				))}
			</Grid>
		);
	};

	const renderTask = () => {
		return (
			<Grid
				container
				direction="column"
				className={classes.rootGrid}
				//xs={12}
				justify="flex-start"
				alignItems="center"
			>
				<Grid
					item
					container
					justify="center"
					className={classes.wordContainer}
				>
					{renderWords()}
				</Grid>
				<Grid
					container
					item
					alignItems="flex-start"
					justify="center"
					className={classes.sentenceContainer}
				>
					{renderQuestions()}
				</Grid>
			</Grid>
		);
	};

	return (
		<DndProvider options={{}} backend={Backend}>
			{renderTask()}
		</DndProvider>
	);
};
