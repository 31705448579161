import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DraggableButton from "./DraggableButton";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import DroppableCardMedia from "./DroppableCardMedia";
import { CurrentHost } from "../../Hosts";
import axios from "axios";
import { StepContext } from "../../Step";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%",
		flexWrap: "nowrap"
	},
	card: {
		maxWidth: 345
	},
	titleContainer: {
		flexBasis: "25%"
	},
	pictureContainer: {
		flexBasis: "50%"
	},
	draggableContainer: {
		flexBasis: "25%"
	}
});

export const DragAndDropPhotosTask = () => {
	const classes = useStyles();
	const { data, solved, sessionKey } = useContext(StepContext);
	const { Urls, Words, CorrectSequence } = data;

	const [droppedWords, setDroppedWords] = useState([]);

	useEffect(() => {
		if (droppedWords.length === CorrectSequence.length) {
			let noHoles = true;
			for (let i = 0; i < droppedWords.length; i++) {
				if (droppedWords[i] == null) {
					noHoles = false;
					break;
				}
			}
			if (noHoles) {
				axios
					.post(`${CurrentHost}:5001/steps/ForceComplete`, {
						key: sessionKey
					})
					.then((response) => {
						if (response.data.success) {
							solved(true);
						}
					});
			}
		}
	}, [droppedWords, CorrectSequence.length, sessionKey, solved]);

	const wordDropped = (index, word) => {
		let words = [...droppedWords];
		words[index] = word.value;
		setDroppedWords(words);
	};

	const renderPhotos = () => {
		return Urls.map((url, index) => (
			<Grid item key={index}>
				<DroppableCardMedia
					image={url}
					wordDropped={wordDropped}
					index={index}
					answer={CorrectSequence[index]}
					dropped={droppedWords[index]}
				/>
			</Grid>
		));
	};

	const renderWords = () => {
		return Words.map((word, index) => (
			<Grid item key={index}>
				<DraggableButton
					disabled={droppedWords.includes(word)}
					value={word}
				>
					<Typography variant="h5">{word}</Typography>
				</DraggableButton>
			</Grid>
		));
	};

	const renderTask = () => {
		return (
			<Grid container className={classes.rootGrid} direction="column">
				<Grid
					item
					container
					className={classes.titleContainer}
					justify="center"
					alignItems="flex-end"
				>
					<Typography variant="h3">{data.Title}</Typography>
				</Grid>
				<Grid
					item
					container
					className={classes.pictureContainer}
					direction="row"
					alignItems="center"
					justify="space-evenly"
				>
					{renderPhotos()}
				</Grid>
				<Grid
					item
					container
					className={classes.draggableContainer}
					direction="row"
					alignItems="center"
					justify="space-evenly"
					wrap="wrap"
				>
					{renderWords()}
				</Grid>
			</Grid>
		);
	};

	return <DndProvider backend={Backend}>{renderTask()}</DndProvider>;
};
