import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Step } from "./components/Step";
import { ModuleEnding } from "./components/Results/ModuleEnding";
import { ShowScore } from "./components/Results/ShowScore";
import { Classroom } from "./components/Classroom";
import { ModuleSelection } from "./components/ModuleSelection";
import {
	MuiThemeProvider,
	createMuiTheme,
	responsiveFontSizes
} from "@material-ui/core/styles";
import "./custom.css";
import { ModuleTOC } from "./components/ModuleTOC";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const theme = responsiveFontSizes(
	createMuiTheme({
		typography: {
			fontFamily: '"Muli", "Roboto"'
			// allVariants: {
			// 	component: "div"
			// }
		}
		// palette: {
		// 	action: {
		// 		hoverOpacity: 0
		// 	}
		// }
	})
);

const App = () => {
	const { trackPageView } = useMatomo();

	// Track page view
	React.useEffect(() => {
		trackPageView();
	}, []);

	return (
		<MuiThemeProvider theme={theme}>
			<Layout>
				<Route exact path="/" component={Classroom} />
				<Route path="/step/:moduleId" component={Step} />
				<Route
					exact
					path="/modules/:package"
					component={ModuleSelection}
				/>
				<Route path="/ending/:sessionKey" component={ModuleEnding} />
				<Route path="/showScore/:sessionKey" component={ShowScore} />
				<Route path="/modules/toc/:module" component={ModuleTOC} />
			</Layout>
		</MuiThemeProvider>
	);
};

export default App;
