import React from "react";
import { CardMedia, Grid, Box, Typography, Paper } from "@material-ui/core";
import Types from "./ItemTypes";
import { useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	// root: {

	// 	borderRadius: "50px"
	// },
	pictureContainer: {
		width: "8vmax",
		height: "8vmax",
		borderRadius: "2vmax",
		borderStyle: "solid",
		borderWidth: "4px",
		boxShadow: ({ dropped }) =>
			dropped
				? "0 1px 4px 3px rgba(0, 217, 206, 0.09)"
				: "0 11px 15px -7px rgba(0, 0, 0, 0.2)",
		borderColor: ({ dropped, isOver }) =>
			dropped ? "#00d9ce" : isOver ? "#d0a2fa" : "#c8cedc"
	},
	paperRoot: {
		width: "15vh",
		height: "5vh",
		borderRadius: "50px",
		boxShadow: ({ dropped }) =>
			dropped
				? "0 1px 4px 3px rgba(0, 217, 206, 0.3), inset 6px 6px 6px 0 rgba(255, 255, 255, 0.5)"
				: "0 1px 4px 3px rgba(0, 0, 0, 0.09)",
		backgroundColor: ({ dropped }) => (dropped ? "#00d9ce" : "inherit"),
		borderStyle: "solid",
		borderWidth: ({ isOver }) => (isOver ? "3px" : "1px"),
		borderColor: ({ isOver }) => (isOver ? "#d0a2fa" : "#c8cedc"),
		color: "#ffffff"
	}
});

const DroppableCardMedia = ({ image, wordDropped, index, answer, dropped }) => {
	const [{ isOver }, drop] = useDrop({
		accept: Types.DESCRIPTION,
		canDrop: (item) => canDropWord(item),
		drop: (item) => wordDropped(index, item),
		collect: (mon) => ({
			isOver: !!mon.isOver()
			//canDrop: !!mon.canDrop()
		})
	});
	const classes = useStyles({ dropped: dropped != null, isOver });

	const canDropWord = (item) => {
		return item.value === answer;
	};

	return (
		<Box ref={drop}>
			<Grid
				item
				container
				alignItems="center"
				direction="column"
				spacing={2}
			>
				<Grid item>
					<CardMedia
						className={classes.pictureContainer}
						image={image}
					/>
				</Grid>
				<Grid item>
					<Paper className={classes.paperRoot}>
						<Grid
							container
							alignItems="center"
							justify="center"
							style={{ height: "100%" }}
						>
							<Typography variant="h5" align="center">
								{dropped != null ? dropped : ""}
							</Typography>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DroppableCardMedia;
