import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CurrentHost } from "../Hosts";
import axios from "axios";
import Trofy from "../../img/trophy.svg";

import "../Step.css";
import ColoredButton from "../Utils/ColoredButton";

const useStyles = makeStyles((theme) => ({
	rootGrid: {
		height: "100%",
		width: "100%",
		margin: "0px"
	},
	fullWH: {
		height: "100%",
		width: "100%"
	},
	contentGrid: {
		flexWrap: "nowrap"
	},
	trofyIcon: {
		height: "25vmax"
	},
	taskGrid: {
		height: "80%",
		width: "35%"
	},
	taskContent: {
		borderRadius: "20px",
		boxShadow: "0 6px 20px 0 rgba(52, 103, 196, 0.3)",
		height: "100%",
		width: "100%"
	}
}));

export const ModuleEnding = (props) => {
	const classes = useStyles();
	const [message, setMessage] = useState(null);
	const textColor = "#152481";
	useEffect(() => {
		const getScore = (key) => {
			axios
				.post(`${CurrentHost}:5001/steps/EndSession`, { key: key })
				.then((response) => {
					if (response.data.success) {
						localStorage.removeItem("sessionKey");
						setMessage(response.data.message);
					} else {
						setMessage("test");
					}
				})
				.catch((error) => {
					console.error(error);
				});
		};

		getScore(props.match.params.sessionKey);
	}, [props.match.params.sessionKey]);

	const handleClick = () => {
		props.history.push(`/`);
	};

	if (message == null) return <Box>Loading please wait...</Box>;
	else
		return (
			<Box className="backgroundImage stepBackdrop">
				<Box className="backgroundImage stepBackground">
					<Grid
						container
						className={classes.rootGrid}
						alignItems="center"
						direction="row"
						justify="center"
					>
						<Grid
							item
							container
							alignItems="center"
							justify="center"
							className={classes.taskGrid}
						>
							<Paper className={classes.taskContent}>
								<Grid
									container
									className={`${classes.fullWH} ${classes.contentGrid}`}
									direction="column"
									alignItems="center"
									justify="space-evenly"
								>
									<Grid
										item
										container
										justify="center"
										alignItems="center"
									>
										<img
											src={Trofy}
											className={classes.trofyIcon}
											alt="Trofy"
										/>
									</Grid>
									<Grid item style={{ flexGrow: 1 }}>
										<Typography>
											<Box
												color={textColor}
												fontWeight="bold"
												fontSize="5vh"
											>
												Gratulerer!
											</Box>
										</Typography>
									</Grid>
									<Grid item style={{ flexGrow: 1 }}>
										<Typography
											align="center"
											component="div"
										>
											<Box
												color={textColor}
												fontSize="3vh"
											>
												Du er nå ferdig med
												<Box fontWeight="bold">
													'{message}'
												</Box>
											</Box>
										</Typography>
									</Grid>
									<Grid item style={{ flexGrow: 1 }}>
										<ColoredButton
											color="#d0a2fa"
											onClick={handleClick}
										>
											<Typography
												style={{
													fontWeight: "bold",
													fontSize: "3vh"
												}}
											>
												Tilbake til startsiden
											</Typography>
										</ColoredButton>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			</Box>
		);
};
