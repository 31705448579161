import React, { useState, useCallback, useContext } from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import Answer from "./Answer";
import update from "immutability-helper";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import ColoredButton from "../../Utils/ColoredButton";
import axios from "axios";
import { CurrentHost } from "../../Hosts";
import { StepContext } from "../../Step";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%",
		width: "100%"
	},
	correctButton: {
		backgroundColor: "green"
	},
	normalButton: {}
});

export const SortableAnswersTask = () => {
	const classes = useStyles();
	const { data, sessionKey, solved } = useContext(StepContext);
	const [answers, setAnswers] = useState(data.Answers);

	const onClick = () => {
		axios
			.post(`${CurrentHost}:5001/steps/ForceComplete`, {
				key: sessionKey
			})
			.then((response) => {
				if (response.data.success) {
					solved(true);
				}
			});
	};

	const moveAnswer = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = answers[dragIndex];
			setAnswers(
				update(answers, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard]
					]
				})
			);
		},
		[answers]
	);

	const renderAnswers = () => {
		return answers.map((answer, index) => (
			<Grid item key={answer} xs={12}>
				<Answer
					key={answer}
					index={index}
					text={answer}
					moveAnswer={moveAnswer}
				/>
			</Grid>
		));
	};

	const renderTask = () => {
		return (
			<Grid
				container
				justify="center"
				alignItems="stretch"
				alignContent="stretch"
				direction="row"
				className={classes.rootGrid}
			>
				<Grid
					item
					container
					justify="center"
					xs={11}
					style={{ marginTop: "5vmax" }}
				>
					<Typography style={{ fontWeight: "bold" }} variant="h4">
						{data.Question}
					</Typography>
				</Grid>
				<Grid
					item
					container
					//alignContent="space-between"
					//alignItems="stretch"
					direction="row"
					spacing={1}
					xs={6}
				>
					{renderAnswers()}
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignItems="center"
					xs={12}
				>
					<Grid item>
						<ColoredButton
							color="rgb(72, 200, 54)"
							variant="contained"
							onClick={onClick}
						>
							<Typography
								style={{
									fontWeight: "bold"
								}}
								variant="h6"
							>
								Neste
							</Typography>
						</ColoredButton>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return <DndProvider backend={Backend}>{renderTask()}</DndProvider>;
};
