import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { Box, Typography, Fade, Grid, Paper } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ColoredButton from "../Utils/ColoredButton";
import CustomCheckbox from "../Utils/CustomCheckbox";
import "./VideoTask.css";
import { StepContext } from "../Step";
import Back from "../../img/previous.svg";

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: "100%"
	},
	paperGrid: {
		flexShrink: 0,
		overflowY: "auto"
	},
	paperContainer: {
		borderRadius: "20px",
		boxShadow: "0 6px 20px 3px rgba(52,103,196,0.09)",
		paddingLeft: "3em",
		paddingRight: "3em",
		overflow: "auto",
		position: "relative"
	},
	textContainer: {
		position: "absolute"
	},
	materialContainer: {
		flexGrow: 1
	},
	buttonContainer: {
		flexBasis: "10%"
	},
	questionContainer: {
		flexBasis: "15%"
	},
	fullWH: {
		width: "100%",
		height: "100%"
	},
	questionBackdrop: {
		borderRadius: "20px",
		background: "linear-gradient(to bottom, #cdecfe, #9f93ec)"
	},
	questionBox: {
		background: "#ffffff",
		backgroundClip: "padding-box",
		border: "solid 4px transparent",
		borderRadius: "20px"
	},
	arrowIcon: {
		height: "100%",
		cursor: "pointer"
	},
	navigationButton: {
		height: "4vmax",
		objectFit: "contain",
		cursor: "pointer"
	}
});

export const CaseStudyTask = () => {
	const { sessionKey, history, data, prevStep } = useContext(StepContext);
	const { Url, CaseQuestions, CaseText } = data;

	const classes = useStyles();
	const [showText, setText] = useState(false);
	const [appear, setAppear] = useState(false);
	const [allChecked, setAllChecked] = useState(false);
	const [currentQuestion, displayQuestion] = useState(0);
	const [questions, setQuestions] = useState(
		CaseQuestions.map((question) => {
			return {
				text: question,
				checked: false
			};
		})
	);

	const fadeTimeout = 1000;

	const handleFinishClick = () => {
		history.push(`/ending/${sessionKey}`);
	};

	const handleResetQuestions = () => {
		setAppear(false);
		setTimeout(() => {
			setQuestions((prevState) => {
				let list = [...prevState];
				list.map((item) => (item.checked = false));
				return list;
			});
			setAllChecked(false);
			setAppear(true);
		}, fadeTimeout);
	};

	const switchText = () => {
		setText(!showText);
		setAppear(true);
	};

	const handleVideoEnd = () => {
		setAppear(true);
	};

	const nextQuestion = () => {
		if (currentQuestion + 1 === questions.length) displayQuestion(0);
		else displayQuestion(currentQuestion + 1);
	};

	const prevQuestion = () => {
		if (currentQuestion - 1 === -1) displayQuestion(questions.length - 1);
		else displayQuestion(currentQuestion - 1);
	};

	const handleNextArrowClick = () => {
		setAppear(false);
		setTimeout(() => {
			nextQuestion();
			setAppear(true);
		}, fadeTimeout);
	};

	const handlePrevArrowClick = () => {
		setAppear(false);
		setTimeout(() => {
			prevQuestion();
			setAppear(true);
		}, fadeTimeout);
	};

	const handleCheck = () => {
		setAppear(false);
		setQuestions((prevState) => {
			let list = [...prevState];
			list[currentQuestion].checked = !prevState[currentQuestion].checked;
			return list;
		});
		setTimeout(() => {
			if (questions.every((question) => question.checked === true)) {
				setAllChecked(true);
			} else {
				nextQuestion();
			}

			setAppear(true);
		}, fadeTimeout);
	};

	const renderVideo = () => {
		return (
			<Box className="videoBackdrop">
				<Box className="videoContainer">
					<ReactPlayer
						url={Url}
						config={{
							vimeo: {
								playerOptions: {
									texttrack: "no"
								}
							}
						}}
						width="100%"
						height="100%"
						playing
						controls={true}
						onEnded={handleVideoEnd}
					/>
				</Box>
			</Box>
		);
	};

	const renderText = () => {
		return (
			<Paper className={`${classes.paperContainer} ${classes.fullWH}`}>
				<Typography
					className={classes.textContainer}
					align="left"
					component="div"
				>
					<Box
						style={{
							fontSize: "3vh",
							// fontWeight: "600",
							color: "#4d5770",
							whiteSpace: "pre-wrap"
						}}
					>
						{CaseText}
					</Box>
				</Typography>
			</Paper>
		);
	};

	return (
		<Grid container direction="column" className={classes.root}>
			<Grid item className={classes.materialContainer}>
				{showText ? renderText() : renderVideo()}
			</Grid>
			<Grid
				item
				container
				direction="row"
				justify="space-evenly"
				alignItems="center"
				className={classes.buttonContainer}
			>
				<Grid
					item
					xs={4}
					item
					container
					alignItems="center"
					justify="flex-start"
				>
					<img
						src={Back}
						onClick={prevStep}
						className={classes.navigationButton}
						alt="Back button"
					/>
				</Grid>
				<Grid
					item
					container
					alignItems="center"
					justify="center"
					xs={4}
				>
					<ColoredButton
						variant="contained"
						size="large"
						color="#d0a2fa"
						onClick={switchText}
					>
						<Box fontSize="2em">{showText ? "Video" : "Tekst"}</Box>
					</ColoredButton>
				</Grid>
				<Grid
					item
					container
					alignItems="flex-end"
					justify="flex-end"
					xs={4}
					style={{ height: "100%" }}
				>
					<Fade in={appear} timeout={fadeTimeout}>
						{!allChecked ? (
							<Grid
								container
								direction="row"
								justify="flex-end"
								alignContent="stretch"
								alignItems="stretch"
								style={{
									color: "#4d5770",
									fontWeight: "bold",
									fontSize: "2rem"
								}}
							>
								<Grid item>
									<ChevronLeftIcon
										className={classes.arrowIcon}
										fontSize="inherit"
										onClick={handlePrevArrowClick}
									/>
								</Grid>
								<Grid item>
									<Typography
										style={{
											color: "#4d5770",
											fontWeight: "bold",
											fontSize: "2rem"
										}}
									>
										{`${currentQuestion + 1}/${
											CaseQuestions.length
										}`}
									</Typography>
								</Grid>
								<Grid item>
									<ChevronRightIcon
										className={classes.arrowIcon}
										fontSize="inherit"
										onClick={handleNextArrowClick}
									/>
								</Grid>
							</Grid>
						) : (
							<Box></Box>
						)}
					</Fade>
				</Grid>
			</Grid>
			<Grid item className={classes.questionContainer}>
				<Fade in={appear} timeout={fadeTimeout}>
					{!allChecked ? (
						<Box
							className={`${classes.questionBackdrop} ${classes.fullWH}`}
						>
							<Box
								className={`${classes.questionBox} ${classes.fullWH}`}
							>
								<Grid
									container
									direction="row"
									style={{ height: "100%" }}
								>
									<Grid
										item
										container
										xs={1}
										alignItems="center"
										justify="center"
									>
										<CustomCheckbox
											checked={
												questions[currentQuestion]
													.checked
											}
											onChange={handleCheck}
											size="2em"
										/>
									</Grid>
									<Grid
										item
										container
										xs={11}
										alignItems="center"
										justify="flex-start"
									>
										<Typography
											style={{
												fontSize: "3vh",
												marginRight: "1rem"
											}}
										>
											<Box color="#4d5770">
												{
													questions[currentQuestion]
														.text
												}
											</Box>
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Box>
					) : (
						<Grid
							item
							container
							justify="center"
							alignItems="center"
							className={classes.fullWH}
							spacing={1}
						>
							<Grid item>
								<ColoredButton
									color="rgb(72, 200, 54)"
									onClick={handleFinishClick}
									variant="contained"
									size="large"
								>
									<Typography variant="h4">
										Fullfør modul
									</Typography>
								</ColoredButton>
							</Grid>
							<Grid item>
								<ColoredButton
									color="red"
									onClick={handleResetQuestions}
									variant="contained"
									size="large"
								>
									<Typography variant="h4">
										Gå tilbake til spørsmålene
									</Typography>
								</ColoredButton>
							</Grid>
						</Grid>
					)}
				</Fade>
			</Grid>
		</Grid>
	);
};
