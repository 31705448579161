import React from "react";
import { useDrag } from "react-dnd";
import Types from "./ItemTypes";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		background: "#d0a2fa",
		textTransform: "none",
		// padding: "15px 50px",
		borderRadius: "20px",
		boxShadow: "0 1px 4px 3px rgba(211, 188, 231, 0.3)",
		color: "white",
		cursor: "move",
		opacity: ({ isDragging, disabled }) => (disabled || isDragging ? 0 : 1)
	}
});

const DraggableButton = ({ value, children, disabled }) => {
	const [{ isDragging }, drag] = useDrag({
		item: { value: value, type: Types.DESCRIPTION },
		collect: monitor => ({
			isDragging: !!monitor.isDragging()
		})
	});
	const classes = useStyles({ isDragging, disabled });

	return (
		<Button
			color="#d0a2fa"
			ref={drag}
			variant="contained"
			disableRipple
			disabled={disabled}
			className={classes.root}
		>
			{children}
		</Button>
	);
};

export default DraggableButton;
