import React, { useContext } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Next from "../../img/next.svg";
import Confetti from "../../img/confetti.png";
import Fire from "../../img/path.svg";
import Lottie from "react-lottie";
import CorrectMarkAnimation from "../../animations/lf30_editor_GbmGQZ.json";
import { StepContext } from "../Step";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%",
		width: "100%",
		backgroundImage: `url(${Confetti})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain"
	},
	checkboxIcon: {
		height: "25vh",
		width: "25vh",
		color: "#00d9ce"
	},
	pointsBox: {
		borderRadius: "33px",
		padding: "16px",
		marginTop: "15px",
		paddingRight: "25px",
		paddingLeft: "25px"
	},
	streakBox: {
		borderRadius: "33px",
		padding: "16px",
		marginTop: "15px",
		paddingRight: "20px",
		paddingLeft: "20px",
		objectFit: "contain"
	},
	fireIcon: {
		height: "2rem",
		width: "auto"
	},
	pointsText: {
		color: "#00d9ce",
		fontWeight: "bold"
	},
	titleText: {
		fontWeight: "bold",
		color: "#4d5770"
		//fontSize: "5vh"
	},
	text: {
		color: "#4d5770"
	},
	nextIcon: {
		width: "7vmax",
		height: "7vmax",
		objectFit: "contain",
		cursor: "pointer"
	}
});

export const CorrectAnswer = () => {
	const classes = useStyles();
	const { nextStep } = useContext(StepContext);

	const defaultOptions = {
		loop: false,
		autoplay: true,

		animationData: CorrectMarkAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	return (
		<Grid
			container
			alignItems="center"
			justify="space-between"
			direction="column"
			className={classes.rootGrid}
		>
			<Grid
				item
				container
				style={{ width: "100%", flexGrow: 2 }}
				alignItems="flex-end"
				justify="center"
			>
				<Lottie
					options={defaultOptions}
					height="10vmax"
					width="10vmax"
					margin={10}
				/>
			</Grid>
			<Grid item style={{ flexGrow: 1 }}>
				<Typography className={classes.titleText} variant="h3">
					Flott!
				</Typography>
			</Grid>
			<Grid
				item
				container
				justify="flex-end"
				alignItems="center"
				style={{ flexGrow: 1 }}
			>
				<Grid item>
					<img
						src={Next}
						className={classes.nextIcon}
						onClick={nextStep}
						alt="Next Button"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
