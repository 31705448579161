import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CurrentHost } from "../Hosts";
import axios from "axios";
import Award from "../../img/award.svg";
import Next from "../../img/next.svg";
import "../Step.css";

const useStyles = makeStyles(theme => ({
	rootGrid: {
		height: "100%",
		width: "100%",
		margin: "0px"
	},
	fullWH: {
		height: "100%",
		width: "100%"
	},
	taskGrid: {
		height: "80%",
		width: "35%"
	},
	contentGrid: {
		flexWrap: "nowrap"
	},
	awardGrid: {
		flexGrow: 3,
		//width: "100%",
		objectFit: "contain"
	},
	awardIcon: {
		height: "100%"
	},
	taskContent: {
		borderRadius: "20px",
		boxShadow: "0 6px 20px 0 rgba(52, 103, 196, 0.3)",
		height: "100%",
		width: "100%"
	},
	nextIcon: {
		height: "7vh",
		objectFit: "contain",
		cursor: "pointer"
	}
}));

export const ShowScore = props => {
	const classes = useStyles();
	const [session, setSession] = useState(null);
	const textColor = "#152481";
	useEffect(() => {
		const getScore = key => {
			axios
				.post(`${CurrentHost}:5001/steps/showScore`, { key: key })
				.then(response => {
					setSession(response.data);
				})
				.catch(error => {
					console.error(error);
				});
		};

		getScore(props.match.params.sessionKey);
	}, [props.match.params.sessionKey]);

	const handleClick = () => {
		props.history.push(`/step/${session.moduleId}`);
	};

	if (session == null) return <Box>Loading please wait...</Box>;
	else
		return (
			<Box className="backgroundImage stepBackdrop">
				<Box className="backgroundImage stepBackground">
					<Grid
						container
						className={classes.rootGrid}
						alignItems="center"
						direction="column"
						spacing={3}
						justify="center"
					>
						<Grid
							item
							container
							alignItems="center"
							justify="center"
							className={classes.taskGrid}
						>
							<Paper className={classes.taskContent}>
								<Grid
									container
									className={`${classes.fullWH} ${classes.contentGrid}`}
									direction="column"
									alignItems="center"
									justify="space-evenly"
								>
									<Grid
										item
										container
										alignItems="center"
										justify="center"
										className={classes.awardGrid}
										style={{ marginTop: "1rem" }}
									>
										<img
											src={Award}
											className={classes.awardIcon}
											alt="Award"
										/>
									</Grid>
									<Grid item style={{ flexGrow: 1 }}>
										<Typography
											style={{
												color: textColor,
												fontWeight: "bold",
												fontSize: "4vh"
											}}
										>
											Gratulerer!
										</Typography>
									</Grid>
									<Grid item style={{ flexGrow: 1 }}>
										<Box
											color={textColor}
											whiteSpace="pre-wrap"
											fontSize="3vh"
											fontFamily="Muli, Roboto"
										>
											<Grid
												container
												direction="row"
												alignItems="center"
												justify="center"
											>
												<Grid item>Du fikk </Grid>
												<Grid item>
													<Box fontWeight="bold">{session.score}</Box>
												</Grid>
												<Grid item> poeng!</Grid>
											</Grid>
										</Box>
									</Grid>
									<Grid item style={{ flexGrow: 1 }}>
										<img
											src={Next}
											className={classes.nextIcon}
											onClick={handleClick}
											alt="Next Button"
										/>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			</Box>
		);
};
