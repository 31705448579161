import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
	root: {
		backgroundColor: props =>
			props.color === "red"
				? "#fb5e55"
				: props.color === "green"
				? "#33db92"
				: props.color,
		textTransform: "none",
		padding: "5px 70px",
		margin: "1rem 0rem 1rem 0rem",
		borderRadius: "20px",
		color: "white",
		fontWeight: "600",

		//boxShadow: "0 1px 4px 3px rgba(211, 188, 231, 0.3)",
		"&:hover": {
			backgroundColor: props =>
				props.color === "red"
					? "#fb5e55"
					: props.color === "green"
					? "#33db92"
					: props.color
			//boxShadow: "0 1px 4px 3px rgba(211, 188, 231, 0.3)"
		}
	}
});

const ColoredButton = props => {
	const { color, ...otherProps } = props;
	const classes = useStyles(props);
	return <Button classes={{ root: classes.root }} {...otherProps} />;
};

export default ColoredButton;
