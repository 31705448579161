import React from "react";
import { Box, Grid, Typography, Paper, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClassroomPhoto from "../img/classroom.svg";
import Logo from "../img/TenkHelse.svg";

const useStyles = makeStyles({
	fullWH: {
		height: "100%",
		width: "100%"
	},
	contentRoot: {
		flexWrap: "nowrap",
		height: "60%",
		width: "50%"
	},
	background: {
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		height: "100%",
		width: "100%"
	},
	buttonGrid: {
		flexGrow: 1
	},
	classroomBackground: {
		backgroundImage: `url(${ClassroomPhoto})`
	},
	classGrid: {
		height: "10vmax",
		width: "10vmax"
	},
	classPaperBackdrop: {
		background: "linear-gradient(to bottom, #00f5fd, #00a1da)",
		width: "100%",
		height: "100%",
		borderRadius: "2vmax"
	},

	classPaper: {
		height: "100%",
		width: "100%",
		borderRadius: "2vmax",
		boxShadow: "0 11px 15px -7px rgba(0, 0, 0, 0.2)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#7d869b",
		backgroundClip: "padding-box"
	},
	classPaperHover: {
		"&:hover": {
			//backgroundColor: "#333",
			border: "solid 8px transparent",
			boxShadow:
				"0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 2px 14px 6px #00edfa",
			color: "#00a1da",
			cursor: "pointer"
		}
	}
});

export const Classroom = (props) => {
	const classes = useStyles();
	return (
		<Box
			className={`${classes.fullWH} ${classes.background} ${classes.classroomBackground}`}
		>
			<Grid
				container
				className={classes.fullWH}
				justify="center"
				alignItems="flex-start"
			>
				<Grid
					item
					container
					justify="center"
					alignItems="center"
					direction="column"
					className={classes.contentRoot}
				>
					<Grid
						item
						container
						justify="center"
						alignItems="center"
						style={{ flexBasis: "10%", flexGrow: 1 }}
					>
						<img
							src={Logo}
							style={{ height: "10vh", width: "auto" }}
							alt="Logo"
						/>
					</Grid>
					<Grid
						item
						container
						justify="center"
						alignItems="flex-start"
					>
						<Typography
							style={{
								fontWeight: "bold",
								color: "#152481"
							}}
							variant="h3"
						>
							Velg ditt trinn
						</Typography>
					</Grid>
					<Grid
						item
						container
						direction="row"
						justify="space-evenly"
						alignItems="center"
						className={classes.buttonGrid}
					>
						<Grid item className={classes.classGrid}>
							<Box className={classes.classPaperBackdrop}>
								<Paper
									className={`${classes.classPaper} ${classes.classPaperHover}`}
									onClick={() => {
										props.history.push("/modules/8");
									}}
								>
									<Typography
										style={{ fontWeight: "bold" }}
										variant="h1"
									>
										8
									</Typography>
								</Paper>
							</Box>
						</Grid>
						<Grid item className={classes.classGrid}>
							<Box className={classes.classPaperBackdrop}>
								<Paper
									className={`${classes.classPaper} ${classes.classPaperHover}`}
									onClick={() => {
										props.history.push("/modules/9");
									}}
								>
									<Typography
										style={{ fontWeight: "bold" }}
										variant="h1"
									>
										9
									</Typography>
								</Paper>
							</Box>
						</Grid>
						<Grid item className={classes.classGrid}>
							<Box className={classes.classPaperBackdrop}>
								<Paper
									className={`${classes.classPaper} ${classes.classPaperHover}`}
									onClick={() => {
										props.history.push("/modules/10");
									}}
								>
									<Typography
										style={{ fontWeight: "bold" }}
										variant="h1"
									>
										10
									</Typography>
								</Paper>
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify="center"
						alignItems="flex-start"
					>
						<Link
							style={{
								fontWeight: "bold",
								color: "#152481"
							}}
							variant="h6"
							target="_blank"
							rel="noopener"
							href="https://www.sandefjord.kommune.no/ungdom"
						>
							Hvor kan jeg få råd og informasjon i min kommune?
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
