import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper } from "@material-ui/core";
import { CurrentHost } from "./Hosts";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "../img/group-2.svg";
import Background from "../img/background.svg";
import ColoredButton from "./Utils/ColoredButton";
import axios from "axios";

const useStyles = makeStyles({
	fullWH: {
		height: "100%",
		width: "100%"
	},
	rootGrid: {
		height: "100%",
		width: "100%",
		margin: "0px"
	},
	contentGrid: {
		height: "85%",
		width: "35%",
		flexWrap: "nowrap"
	},
	taskGrid: {
		flexGrow: 1,
		height: "100%",
		boxShadow:
			"0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
		borderRadius: "20px"
	},
	background: {
		backgroundSize: "auto 100%",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "right",
		height: "100%",
		width: "100%"
	},
	classroomBackdrop: {
		backgroundImage: `url(${Backdrop})`
	},
	classroomBackground: {
		backgroundImage: `url(${Background})`
	},
	titleText: {
		fontWeight: "bold",
		fontStyle: "italic",
		fontSize: "2rem",
		paddingBottom: "2rem"
	},
	sectionText: {
		fontWeight: "bold",
		fontStyle: "italic"
	},
	tocSection: {
		paddingBottom: "2rem"
	},
	tocItem: {
		paddingLeft: "3rem"
	},
	tocItemP: {
		cursor: "pointer",
		paddingLeft: "10px",
		paddingRight: "10px",
		color: "#27AAE1",
		"&:hover": {
			color: "white",
			backgroundColor: "#27AAE1",
			borderRadius: "20px"
		}
	},
	mask: {
		width: "10%",
		//height: "100%",
		borderTopLeftRadius: "20px",
		borderBottomLeftRadius: "20px",
		borderTopRightRadius: "0px",
		borderBottomRightRadius: "0px",
		backgroundColor: "#FFF6D1",
		boxShadow: "none",
		background:
			"radial-gradient(circle 50px, #fff 35%, #fff 0%, transparent 0%)",
		backgroundRepeat: "space",
		backgroundSize: "100% 100px"
	},
	taskContent: {
		borderTopRightRadius: "20px",
		borderBottomRightRadius: "20px",
		borderTopLeftRadius: "0px",
		borderBottomLeftRadius: "0px",
		height: "100%",
		overflow: "auto",
		width: "90%",
		position: "relative",
		backgroundColor: "#FFF6D1",
		boxShadow: "none",
		background: `linear-gradient(#FBEAC0 0%, #FFF6D1 8%) 0 4px`,
		backgroundRepeat: "space",
		backgroundSize: "100% 40px"
	}
});

export const ModuleTOC = ({ history, match }) => {
	const [toc, setTOC] = useState({ sections: [] });
	const moduleId = match.params.module;
	const classes = useStyles();
	const getModuleTOC = () => {
		axios
			.get(`${CurrentHost}:5001/steps/GetTOC/${moduleId}`)
			.then((response) => {
				if (response.status === 200) {
					setTOC(response.data);
				}
			});
	};

	useEffect(() => {
		getModuleTOC();
	}, [moduleId]);

	const prepareSession = (index) => {
		axios
			.post(`${CurrentHost}:5001/steps/PrepareSession`, {
				moduleId: moduleId,
				index: index
			})
			.then((response) => {
				if (response.status === 200) {
					localStorage.setItem(
						`sessionKey-${moduleId}`,
						response.data
					);
					history.push(`/step/${moduleId}`);
				}
			});
	};

	const startPress = () => {
		history.push(`/step/${moduleId}`);
	};

	const renderTOC = () => {
		return toc.sections.map((section) => (
			<Grid
				item
				container
				className={classes.tocSection}
				justify="flex-start"
			>
				<Grid item container>
					<Typography className={classes.sectionText}>
						{section.header}
					</Typography>
				</Grid>
				{section.items.map((item) => (
					<Grid item container className={classes.tocItem}>
						<Typography
							className={classes.tocItemP}
							onClick={() => prepareSession(item.index)}
						>
							{item.name}
						</Typography>
					</Grid>
				))}
			</Grid>
		));
	};

	return (
		<Box
			className={`${classes.fullWH} ${classes.background} ${classes.classroomBackdrop}`}
		>
			<Box
				className={`${classes.fullWH} ${classes.background} ${classes.classroomBackground}`}
			>
				<Grid
					container
					className={classes.rootGrid}
					alignItems="center"
					//direction="column"
					justify="center"
				>
					<Grid
						item
						container
						alignItems="center"
						justify="center"
						direction="column"
						className={classes.contentGrid}
					>
						<Grid item container className={classes.taskGrid}>
							<Paper className={classes.mask}></Paper>
							<Paper className={classes.taskContent}>
								<Grid
									container
									className={classes.fullWH}
									style={{ padding: "2rem" }}
								>
									<Grid
										item
										container
										style={{ width: "100%" }}
										justify="center"
										alignItems="center"
									>
										<Typography
											align="center"
											className={classes.titleText}
										>
											Mine oppgaver
										</Typography>
									</Grid>
									<Grid
										item
										container
										direction="column"
										alignItems="center"
										justify="center"
									>
										{renderTOC()}
									</Grid>
									<Grid
										item
										container
										alignItems="center"
										justify="center"
									>
										<ColoredButton
											color="rgb(72, 200, 54)"
											variant="contained"
											onClick={startPress}
										>
											<Typography
												style={{
													fontWeight: "bold"
												}}
											>
												Start
											</Typography>
										</ColoredButton>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
