import React, { useEffect, useContext, useRef } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { CurrentHost } from "../Hosts";
import ColoredButton from "../Utils/ColoredButton";
import axios from "axios";
import "./VideoTask.css";
import { StepContext } from "../Step";

export const NRKVideoTask = () => {
	const { data, sessionKey, nextStep } = useContext(StepContext);
	const containerRef = useRef();

	const onClick = () => {
		axios
			.post(`${CurrentHost}:5001/steps/ForceComplete`, {
				key: sessionKey
			})
			.then((response) => {
				if (response.data.success) {
					nextStep();
				}
			});
	};

	useEffect(() => {
		const div = document.createElement("div");
		div.className = "nrk-video";
		div.dataset.nrkId = data.VideoId;
		div.dataset.nrkAutoplay = "true";
		containerRef.current.appendChild(div);
		window.nrk.embed.video.initialize();
		return () => {
			var videoContainer = document.querySelector(`div[data-nrk-id]`);
			videoContainer.parentNode.removeChild(videoContainer);
		};
	}, [data.VideoId]);

	return (
		<>
			<Box className="videoBackdrop nrkVideoContainer">
				<Box
					className="videoContainer nrkVideoContainer"
					ref={containerRef}
				></Box>
			</Box>

			<Grid container alignItems="center" justify="center">
				<ColoredButton
					color="rgb(72, 200, 54)"
					variant="contained"
					onClick={onClick}
				>
					<Typography
						style={{
							fontWeight: "bold",
							fontSize: "3vh"
						}}
					>
						Neste
					</Typography>
				</ColoredButton>
			</Grid>
		</>
	);
};
