import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CurrentHost } from "./Hosts";
import Backdrop from "../img/group-2.svg";
import Background from "../img/background.svg";
import axios from "axios";
import { ModuleItem } from "./ModuleItem";

const useStyles = makeStyles({
	fullWH: {
		height: "100%",
		width: "100%"
	},
	contentRoot: {
		height: "70%",
		width: "75%"
	},
	background: {
		backgroundSize: "auto 100%",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "right",
		height: "100%",
		width: "100%"
	},
	classroomBackdrop: {
		backgroundImage: `url(${Backdrop})`
	},
	classroomBackground: {
		backgroundImage: `url(${Background})`
	}
});

export const ModuleSelection = (props) => {
	const packageName = props.match.params.package;
	const classes = useStyles();
	const [modules, setModules] = useState([]);

	const getModules = () => {
		axios
			.get(`${CurrentHost}:5001/steps/Modules/${packageName}`)
			.then((response) => {
				setModules(response.data);
			});
	};

	useEffect(() => {
		getModules();
	}, [packageName]);

	const handleClick = (moduleId) => {
		props.history.push(`/modules/toc/${moduleId}`);
	};

	return (
		<Box
			className={`${classes.fullWH} ${classes.background} ${classes.classroomBackdrop}`}
		>
			<Box
				className={`${classes.fullWH} ${classes.background} ${classes.classroomBackground}`}
			>
				<Grid
					container
					className={classes.fullWH}
					alignItems="center"
					justify="center"
				>
					<Grid
						item
						container
						className={classes.contentRoot}
						//direction="row"
						alignItems="center"
						justify="center"
						spacing={3}
					>
						<Grid
							item
							container
							//style={{ flexGrow: 1 }}
							xs={12}
							alignItems="center"
							justify="center"
						>
							<Typography
								variant="h2"
								style={{
									// fontSize: "5vh",
									fontWeight: "bold",
									color: "#152481"
								}}
							>
								{packageName}. Klassetrinn
							</Typography>
						</Grid>
						<Grid
							item
							container
							//style={{ flexGrow: 1 }}
							//direction="row"
							alignItems="center"
							justify="space-evenly"
							spacing={3}
						>
							{modules.map((oneModule) => (
								<ModuleItem
									key={oneModule.id}
									{...oneModule}
									handleClick={handleClick}
								/>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
