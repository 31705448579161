import React, { useEffect, useState, Fragment, createContext } from "react";
import { CurrentHost } from "./Hosts";
import axios from "axios";
import {
	Typography,
	Grid,
	LinearProgress,
	Box,
	Paper,
	Container
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QuestionSingleAnswerTask } from "./Tasks/QuestionSingleAnswerTask";
import { VideoTask } from "./Tasks/VideoTask";
import { DisplayTextTask } from "./Tasks/DisplayTextTask";
import { DragAndDropAnswersTask } from "./Tasks/DragAndDropAnswers/DragAndDropAnswersTask";
import { CorrectAnswer } from "./Results/CorrectAnswer";
import { WrongAnswer } from "./Results/WrongAnswer";
import { QuestionMultipleAnswersTask } from "./Tasks/QuestionMultipleAnswersTask";
import { DragAndDropPhotosTask } from "./Tasks/DragAndDropPhotos/DragAndDropPhotosTask";
import { CaseStudyTask } from "./Tasks/CaseStudyTask";
import { FillInAnswerTask } from "./Tasks/FillInAnswerTask";
import { SortableAnswersTask } from "./Tasks/SortableAnswers/SortableAnswersTask";
import { NRKVideoTask } from "./Tasks/NRKVideoTask";
import Next from "../img/next.svg";
import Back from "../img/previous.svg";
// import Sound from "react-sound";
// import Music from "../music.mov";

import "./Step.css";

const useStyles = makeStyles((theme) => ({
	rootGrid: {
		height: "100%",
		width: "100%",
		margin: "0px"
	},
	contentGrid: {
		height: "85%",
		width: "70%",
		flexWrap: "nowrap"
	},
	taskGrid: {
		flexGrow: 1
	},
	progressGrid: {
		flexBasis: "7%"
	},
	navigationButton: {
		height: "4vmax",
		objectFit: "contain",
		cursor: "pointer"
	},
	taskContent: {
		borderTopRightRadius: "20px",
		borderBottomRightRadius: "20px",
		borderTopLeftRadius: "0px",
		borderBottomLeftRadius: "0px",
		height: "100%",
		width: "97%",
		position: "relative"
	},
	progressBar: {
		width: "25vw",
		height: "2vmax",
		borderRadius: "20px",
		backgroundColor: "#ffffff", //"#00d9ce"
		boxShadow: "0 1px 26px 8px rgba(218, 229, 236, 0.51)",
		border: "solid 1px gray"
	},
	progressBarColor: {
		backgroundColor: "#48c836" //"#00d9ce"
	},
	mask: {
		width: "3%",
		//height: "100%",
		borderTopLeftRadius: "20px",
		borderBottomLeftRadius: "20px",
		borderTopRightRadius: "0px",
		borderBottomRightRadius: "0px",
		backgroundColor: "#1aa6db"
		//background: "linear-gradient(to top, #6002e4, #9606f4)"
	}
}));
export const StepContext = createContext(undefined);
export const Step = ({ history, match }) => {
	const classes = useStyles();
	const [step, setStep] = useState(null);
	const [solved, setSolved] = useState(null);
	const [message, setMessage] = useState(
		"Det blir ikke helt riktig. I den kognitive diamant lærte vi at det ofte er andre veien. Gjennom å endre tankene endrer vi hvordan vi føler."
	);
	// const [musicStatus, setMusicStatus] = useState("STOPPED");
	// const [musicVolume, setMusicVolume] = useState(0);
	const [completed, setCompleted] = useState(0);

	const getStep = (key) => {
		let url = `${CurrentHost}:5001/steps?moduleId=${match.params.moduleId}`;
		if (key != null) url += `&key=${key}`;
		axios
			.get(url)
			.then((response) => {
				response.data.taskData = JSON.parse(response.data.taskData);
				localStorage.setItem(
					`sessionKey-${match.params.moduleId}`,
					response.data.key
				);
				setStep(response.data);
				setSolved(null);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const skipStep = () => {
		navigationRequest("SkipTask");
	};

	const prevStep = () => {
		if (step.currentIndex !== 0) {
			navigationRequest("PrevTask");
		}
	};

	const navigationRequest = (actionToCall) => {
		axios
			.post(`${CurrentHost}:5001/steps/${actionToCall}`, {
				key: step.key
			})
			.then((response) => {
				if (response.status === 200) {
					getStep(step.key);
				}
			});
	};

	const nextStep = () => {
		if (step.currentIndex < step.lastIndex) {
			getStep(step.key);
		} else {
			history.push(`/ending/${step.key}`);
		}
	};

	useEffect(() => {
		getStep(localStorage.getItem(`sessionKey-${match.params.moduleId}`));
	}, [match.params.moduleId]);

	useEffect(() => {
		if (step != null) {
			if (step.finished) {
				nextStep();
			} else {
				setCompleted((step.currentIndex / step.lastIndex) * 100);
			}
		}
	}, [step]);

	// const increaseMusicVolume = () => {
	// 	for (let i = 1; i < 20; i++) {
	// 		setTimeout(() => {
	// 			setMusicVolume(i);
	// 		}, i * 200);
	// 	}
	// };

	// const startMusic = () => {
	// 	if (musicStatus != "PLAYING") {
	// 		setMusicStatus("PLAYING");
	// 		increaseMusicVolume();
	// 	}
	// };

	// const pauseMusic = () => {
	// 	if (musicStatus != "STOPPED") {
	// 		setMusicStatus("STOPPED");
	// 		setMusicVolume(0);
	// 	}
	// };

	const renderComponent = () => {
		switch (step.taskData.TaskNumber) {
			case 1:
				// startMusic();
				return renderPaperElement(<QuestionSingleAnswerTask />);
			case 2:
				// startMusic();
				return renderPaperElement(<QuestionMultipleAnswersTask />);
			case 3:
				// startMusic();
				return renderPaperElement(<DragAndDropAnswersTask />);
			case 4:
				// startMusic();
				return renderPaperElement(<DragAndDropPhotosTask />);
			case 5:
				// pauseMusic();
				return renderPaperElement(<SortableAnswersTask />);
			case 6:
				// startMusic();
				return renderPaperElement(<FillInAnswerTask />);
			case 7:
				return <DisplayTextTask />;
			case 8:
				// pauseMusic();
				return <VideoTask />;
			case 9:
				// pauseMusic();
				return <CaseStudyTask />;
			case 10:
				// pauseMusic();
				return <NRKVideoTask />;
			default:
				return <div>Done Loading</div>;
		}
	};

	const renderResult = () => {
		if (solved) {
			return renderPaperElement(<CorrectAnswer />);
		} else {
			return renderPaperElement(<WrongAnswer />);
		}
	};

	const renderPaperElement = (element) => {
		return (
			<Fragment>
				<Paper className={classes.mask} elevation={3}></Paper>
				<Paper className={classes.taskContent} elevation={3}>
					<Box className="paperclip" />
					{element}
				</Paper>
			</Fragment>
		);
	};

	if (step === null || step.finished)
		return <Typography variant="h3">Laster inn...</Typography>;
	else
		return (
			<StepContext.Provider
				value={{
					sessionKey: step.key,
					data: step.taskData,
					solved: setSolved,
					nextStep: nextStep,
					message: setMessage,
					currentMessage: message,
					history: history,
					prevStep: prevStep
				}}
			>
				<Box className="backgroundImage stepBackdrop">
					<Box className="backgroundImage stepBackground">
						{/* <Sound
						volume={musicVolume}
						url={Music}
						playStatus={musicStatus}
						autoLoad
						loop
					/> */}
						<Grid
							container
							className={classes.rootGrid}
							alignItems="center"
							//direction="column"
							justify="center"
						>
							<Grid
								item
								container
								alignItems="center"
								justify="center"
								direction="column"
								className={classes.contentGrid}
							>
								<Grid
									item
									container
									className={classes.taskGrid}
								>
									{solved != null
										? renderResult()
										: renderComponent()}
								</Grid>
								{step.taskData.TaskNumber !== 9 && (
									<Grid
										item
										container
										alignItems="center"
										justify="space-between"
										className={classes.progressGrid}
									>
										<img
											src={Back}
											className={classes.navigationButton}
											onClick={prevStep}
											alt="Back button"
										/>

										<LinearProgress
											//className={classes.progressBar}
											classes={{
												determinate:
													classes.progressBar,
												barColorPrimary:
													classes.progressBarColor
											}}
											variant="determinate"
											value={completed}
										/>
										<img
											src={Next}
											className={classes.navigationButton}
											onClick={skipStep}
											alt="Next button"
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Box>
				</Box>
			</StepContext.Provider>
		);
};
