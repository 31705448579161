import React, { useContext } from "react";
import { Typography, Paper, Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ColoredButton from "../Utils/ColoredButton";
import TimerIcon from "@material-ui/icons/Timer";
import axios from "axios";
import { CurrentHost } from "../Hosts";
import { StepContext } from "../Step";

const useStyles = makeStyles({
	rootGrid: {},
	contentGrid: {
		width: "75%",
		height: "50%"
	},
	contentPaper: {
		width: "100%",
		height: "100%",
		borderRadius: "10px",
		boxShadow: "0 3px 21px 0 #dee7ed",
		border: "solid 1px rgba(151, 151, 151, 0.1)",
		backgroundColor: "#eff3f6",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	text: {
		color: "#152481",
		fontWeight: "bold"
		//fontSize: "4vh"
	}
});

export const DisplayTextTask = () => {
	const classes = useStyles();
	const { sessionKey, nextStep, data } = useContext(StepContext);
	// const [timer, setTimer] = useState(5);

	// useEffect(() => {
	// 	const countdownInterval = setInterval(() => {
	// 		if (timer != 0) setTimer(timer - 1);
	// 		if (timer === 0) {
	// 			axios
	// 				.post(`${CurrentHost}:5001/steps/ForceComplete`, {
	// 					key: sessionKey
	// 				})
	// 				.then((response) => {
	// 					if (response.data.success) {
	// 						nextStep();
	// 					}
	// 				});
	// 			clearInterval(countdownInterval);
	// 		}
	// 	}, 1000);

	// 	return () => clearInterval(countdownInterval);
	// }, [timer]);

	const onClick = () => {
		axios
			.post(`${CurrentHost}:5001/steps/ForceComplete`, {
				key: sessionKey
			})
			.then((response) => {
				if (response.data.success) {
					nextStep();
				}
			});
	};

	return (
		<Paper className={classes.contentPaper}>
			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				spacing={10}
			>
				<Grid item xs={11}>
					<Typography
						className={classes.text}
						variant="h3"
						align="center"
					>
						{data.Text}
					</Typography>
				</Grid>
				<Grid item>
					{/* <CircularProgress
						variant="static"
						style={{ color: "#152481" }}
						size="4rem"
						value={(10 - timer * 2) * 10}
					/> */}
					<ColoredButton
						color="rgb(72, 200, 54)"
						variant="contained"
						onClick={onClick}
					>
						<Typography
							style={{
								fontWeight: "bold",
								fontSize: "3vh"
							}}
						>
							Neste
						</Typography>
					</ColoredButton>
					{/* <Typography
						className={classes.text}
						variant="h1"
						align="center"
					>
						{timer}
						<TimerIcon
							style={{ height: "100%" }}
							fontSize="inherit"
						/>
					</Typography> */}
				</Grid>
			</Grid>
		</Paper>
	);
};
