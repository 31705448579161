//import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
	urlBase: "https://analytics.lifekeyshealth.com/",
	siteId: 1,
	configurations: {
		// optional, default value: {}
		// any valid matomo configuration, all below are optional
		disableCookies: true,
		setSecureCookie: true,
		setRequestMethod: "POST"
	}
});

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
	<MatomoProvider value={instance}>
		<BrowserRouter basename={baseUrl}>
			<App />
		</BrowserRouter>
	</MatomoProvider>,
	rootElement
);

registerServiceWorker();
