import React, { useState, useContext } from "react";
import { Grid, Typography, FormControlLabel, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CurrentHost } from "../Hosts";
import CustomCheckbox from "../Utils/CustomCheckbox";
import axios from "axios";
import ColoredButton from "../Utils/ColoredButton";
import { StepContext } from "../Step";

const useStyles = makeStyles({
	rootGrid: {
		height: "100%",
		width: "100%",
		flexWrap: "nowrap"
	},
	correctCheckbox: {
		color: "green"
	},
	wrongCheckbox: {
		color: "red"
	},
	titleContainer: {
		marginTop: "5vmax"
	},
	answerContainer: {
		flexGrow: 2
	},
	buttonContainer: {
		//flexBasis: "15%"
		flexGrow: 1
	}
});

export const QuestionMultipleAnswersTask = () => {
	const classes = useStyles();
	const { data, sessionKey, solved } = useContext(StepContext);
	const [correctAnswers, setCorrectAnswers] = useState([]);
	const [wrongAnswers, setWrongAnswers] = useState([]);
	const [checked, setChecked] = useState([]);

	const checkAnswer = () => {
		let orderedAnswer = [];
		data.Answers.forEach((answer) => {
			if (checked.includes(answer)) {
				orderedAnswer.push(answer);
			}
		});

		axios
			.post(`${CurrentHost}:5001/steps/QuestionMultipleAnswers`, {
				key: sessionKey,
				answers: orderedAnswer
			})
			.then((response) => {
				if (response.data.success) {
					solved(true);
				}
				setAnswers(response.data.correctAnswers);
			});
	};

	const setAnswers = (correct) => {
		let CA = [...correctAnswers];
		let WA = [...wrongAnswers];
		checked.forEach((checkbox) => {
			if (correct.includes(checkbox)) {
				if (!correctAnswers.includes(checkbox)) {
					CA.push(checkbox);
				}
			} else {
				if (!wrongAnswers.includes(checkbox)) {
					WA.push(checkbox);
				}
			}
		});
		setCorrectAnswers(CA);
		setWrongAnswers(WA);
	};

	const handleChange = (event) => {
		const exists = checked.includes(event.target.id);
		if (event.target.checked && !exists) {
			setChecked([...checked, event.target.id]);
		} else if (!event.target.checked && exists) {
			let newChecked = [...checked];
			newChecked.splice(
				newChecked.findIndex((a) => a === event.target.id),
				1
			);
			setChecked(newChecked);
		}
	};

	const getCheckboxClass = (text) => {
		if (wrongAnswers.includes(text)) {
			return classes.wrongCheckbox;
		} else if (correctAnswers.includes(text)) {
			return classes.correctCheckbox;
		} else {
			return "";
		}
	};

	return (
		<Grid container className={classes.rootGrid} direction="column">
			<Grid
				item
				container
				className={classes.titleContainer}
				alignItems="flex-end"
				justify="center"
			>
				<Grid item xs={11}>
					<Typography
						style={{
							fontWeight: "bold"
						}}
						variant="h4"
					>
						{data.Question}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				container
				className={classes.answerContainer}
				alignItems="center"
				justify="center"
			>
				<Grid
					item
					container
					direction="column"
					alignItems="flex-start"
					justify="space-evenly"
					style={{ height: "100%" }}
					xs={11}
				>
					{data.Answers.map((answer) => (
						<Grid item>
							<FormControlLabel
								key={answer}
								control={
									<CustomCheckbox
										size="3vmax"
										id={answer}
										onChange={handleChange}
									/>
								}
								label={
									<Typography
										variant="h5"
										className={getCheckboxClass(answer)}
									>
										{answer}
									</Typography>
								}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid
				item
				container
				className={classes.buttonContainer}
				alignItems="center"
				justify="center"
			>
				<ColoredButton
					variant="contained"
					color="rgb(72, 200, 54)"
					onClick={checkAnswer}
				>
					<Typography variant="h5" style={{ fontWeight: "bold" }}>
						Sjekk
					</Typography>
				</ColoredButton>
			</Grid>
		</Grid>
	);
};
