import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	moduleContainer: {
		borderRadius: "50px",
		//marginTop: "5vh",
		boxShadow:
			"0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 6px 20px 3px rgba(0, 0, 0, 0.3)",
		minHeight: "50vh",
		backgroundImage: ({ color }) => color,
		backgroundSize: "inherit",
		cursor: "pointer"
	},
	moduleText: {
		fontWeight: "bold",
		color: "#ffffff",
		wordWrap: "break-word",
		width: "100%"
	}
});

export const ModuleItem = ({ id, name, color, icon, handleClick }) => {
	const classes = useStyles({ color });
	return (
		<Grid item md={3} xs={12}>
			<Grid
				container
				className={classes.moduleContainer}
				justify="center"
				onClick={() => handleClick(id)}
			>
				<Grid item container alignItems="center" justify="center">
					<img
						src={require(`../img/${icon}`)}
						style={{ maxWidth: "100%" }}
						alt="Module icon"
					/>
				</Grid>
				<Grid item container justify="center" xs={12}>
					<Typography
						align="center"
						variant="h4"
						className={classes.moduleText}
					>
						{name}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
